@tailwind base;
@tailwind components;
@tailwind utilities;

.overflow-hidden {
  overflow: hidden;
}


body{
  /* background-color: #08070C; */
  background-color: white;
  
}
.bg-black-variation{
  /* background-color: #08070C; */
}



.rubik-wet-paint-regular {
  font-family: "Rubik Wet Paint", system-ui;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.2rem;
}
/* 
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 700 */

.edu-au-vic-wa-nt-pre-ariel {
  font-family: "Edu AU VIC WA NT Pre", cursive;
  font-optical-sizing: auto;
  font-style: normal;
}
.ultra-regular {
  font-family: "Ultra", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
}



.network-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 5px;
}

/* Add this CSS to your styles */
.fixed {
  position: fixed;
}



.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.z-50 {
  z-index: 50;
}





/* Ariel */

.glass-head{
/* From https://css.glass */
background: rgba(255, 255, 255, 0.24);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(9.9px);
-webkit-backdrop-filter: blur(9.9px);
border: 1px solid rgba(255, 255, 255, 0.45);

}

.custom-shape-divider-bottom-1731659674 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1731659674 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 161px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1731659674 .shape-fill {
  fill: #FFFFFF;
}




/* FLIP CARD ANIMATION */


*, *:before, *:after {
  box-sizing: inherit;
}

.text-center {
  text-align: center;
}

.color-white {
  color: #fff;
}

.box-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 35px 15px;
  width: 100%;
}

@media screen and (min-width:1380px) {
  .box-container {
    flex-direction: row
  }
}

.box-item {
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 400px;
  margin-bottom: 35px;
  max-width: 100%;
}

.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1200px;
  -webkit-perspective: 1200px;
}

.flip-box-front,
.flip-box-back {
  background-size: cover;
  /* background-position: center; */
  border-radius: 8px;
  min-width: 50%;
  min-height: 415px;
  -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front {
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition-duration: 1000ms;
}

.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition-duration: 1000ms;
}

.flip-box .inner {
  position: absolute;
  left: 0;
  width: 100%;
  /* padding: 60px; */
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  
  transform: translateY(-50%) translateZ(120px) scale(.94);
  -webkit-transform: translateY(-50%) translateZ(120px) scale(.94);
  -ms-transform: translateY(-50%) translateZ(120px) scale(.94);
  top: 50%;
}

.flip-box-header {
  /* font-size: 34px; */
}

.flip-box p {
  font-size: 20px;
  line-height: 1.5em;
}

.flip-box-img {
  margin-top: 25px;
  /* padding:10rem; */
}

.flip-box-button {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 2px;
  color: black;
  cursor: pointer;
  /* font-size: 20px; */
  font-weight: bold;
  margin-top: 25px;
  padding: 15px 20px;
  text-transform: uppercase;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #72eaba;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
